.article-filter {
  margin: 0; padding: 1em 0em;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;

  li {
    margin-right: 1em;
    font-size: 0.9em;

    &:last-of-type {
      margin-right: 0em;
    }

    &:first-of-type {
      width: 100%;
    }
  }

  a {
    transition: color .5s ease;
  }
}

.category-type-working-remotely {
  color: $blue;

  &:hover {
    color: lighten($blue, 20%);
  }
}
.category-type-career {
  color: purple;

  &:hover {
    color: lighten(purple, 20%);
  }
}

.category-type-tech {
  color: rgb(20, 97, 20);

  &:hover {
    color: lighten(rgb(20, 97, 20), 20%);
  }
}

@media(min-width: $bp-md) {
  .article-filter {

    li:first-of-type {
      width: auto;
    }
  }
}