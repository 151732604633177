.hdr-home {
  position: relative;
  overflow: hidden;

  .inner {
    position: relative;
    padding: 7em 0em;
  }
}

.hdr-home .hdr-content {
  position: relative;
  max-width: 500px;
  z-index: 7;
  color: white;
  padding: 8em 0em 6em 0em;
}


.sleeves {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 4;

  &__item {
    width: 20%;
    height: 0%;
    position: absolute;
    bottom: 0;
    background: $blue;
    transition: height 600ms $ease-out-cubic;

    @for $i from 1 through 6 {
      &:nth-child(#{$i + 1}) {
        left: 20% * $i;
      }
    }
  }
}


.loaded {

  .sleeves {

    &__item {
      height: 100%;

      @for $i from 1 through 6 {
        &:nth-child(#{$i + 1}) {
          transition-delay: 120ms * $i;
        }
      }
    }
  }
}



.hdr-home .sleeve-text__item {
  position: relative;
  display: inline-block;
  overflow: hidden;

  span {
    position: relative;
    display: inline-block;
    transform: translateY(100%);
  }
}


.home-hero-bg {

  .section-bg__el {
    transform: scale(1.1);
    opacity: 0;
  }
}


.set {
  .sleeves__item {
    background-color: rgba($blue, 0.9);
    transition-delay: 0ms !important;
    transition: background 1500ms ease;
  }

  .sleeve-text__item {

    span {
      transform: translateY(0%);
      transition: transform 700ms ease;
    }
  }

  .home-hero-bg {

    .section-bg__el {
      transform: scale(1);
      opacity: 1;
      transition: transform 2s, opacity 1s ease;
    }
  }

}

.hdr-follow-links {
  position: absolute;
  z-index: 5;
  bottom: 30px;

  ul {
    list-style-type: none;
    margin: 0; padding: 0;
    display: flex;
  }

  li {
    margin-right: 2em;
    transform: translateY(10px);
    opacity: 0;
    &:last-of-type {margin-right: 0em}
  }

  svg {
    stroke: white;
  }
}


.set {
  .hdr-follow-links {

    li {
      transform: translateY(0px);
      opacity: 1;
      transition: transform 400ms, opacity 400ms ease;

      @for $i from 0 through 10 {
        &:nth-child(#{$i}) {
          transition-delay: 120ms * $i - 1;  
        }
      }
    }
  }
}



@media(min-width: $bp-md) {
  .hdr-home .hdr-content {
    max-width: 700px;
  }
  .hdr-home {
    position: relative;
    height: 100vh;
    width: 100%;

    .inner {
      height: 100vh;
      position: relative;
      padding: 0em;
    }
  }

  .hdr-content {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    z-index: 7;
    color: white;
    padding: 2em 0em;
  }

}


@media(min-width: $bp-lg) {
  .hdr-home .hdr-content {
    max-width: 760px;
  }
}