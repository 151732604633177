.footer-columns {
  padding: 2em 0em;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-between;

  &__item {
    width: 100%;
    align-self: center;
    margin-bottom: 1em;

    &:last-of-type {
      padding: 1em 0em;
      margin-bottom: 0em;
    }

    &:nth-child(2) {
      text-align: center;
    }
  }

  &__social {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;

    li {
      margin-right: 2em;

      &:last-of-type {
        margin-right: 0em;
      }
    }
  }
}


.bu {
  @include btn-reset();
  cursor: pointer;
  background: transparent;
  padding: 1em 2em;
  position: relative;

  &:hover { background: #eee; transition: background 400ms ease;}

  &:active { top: 1px; }
}

@media(min-width: $bp-md) {
  .footer-columns {
  
    &__item {
      width: 33%;
      margin-bottom: 0em;
  
      &:last-of-type {
        text-align: right;
        padding: 0em;
      }
  
      &:nth-child(2) {
        text-align: center;
      }
    }
  
    &__social {
      justify-content: center;
    }
  }
  
}