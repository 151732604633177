.work-nav {
  position: relative;

  ul {
    margin: 0; padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    position: relative;
  }

  li {
    margin-right: 2em;
    font-weight: 800;
    color: $black;
    cursor: pointer;

    &:last-of-type {
      margin-right: 0em;
    }

    &.active {
      color: $black;
    }
  }
}

.indicator {
  position: absolute;
  bottom: 0;
  width: 0px;
  height: 2px;
  background: $blue;
  left: 0;
  transition: all 300ms ease;
}


.work-containers {

  &__item {
    overflow: hidden;
    width: 0; height: 0;
    visibility: hidden;
  }
}

.work-containers__item.active {
  display: block !important;
  overflow: visible;
  width: auto; height: auto;
  visibility: visible;
}

.work-containers .two-col-grid {
  padding: 4em 0em 2em 0em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__item {
    width: 100%;
    margin-bottom: 1em;
    display: inline-block;
    text-decoration: none;
    color: $black;
    font-size: 1em;
  }

  img {
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(black, 0.05);

    &:hover {
      transition: opacity 200ms, transform 200ms ease;
    }
  }
}

.work-containers__item.active {
  .two-col-grid { 
    transition: opacity 800ms, transform ease;
  }
}

.card-details {
  position: relative;
  // top: -50%;
  top: -2em;
  // transform: translateY(-50%);
  left: 8%;
  display: inline-block;
  color: $black;
  max-width: 70%;

  p {
    position: relative;
    z-index: 5;
    padding: 1em 2em;
    margin: 0;
    font-weight: 800;
  }

  span {
    @include fill-parent();
    background: white;
    transition: width 400ms ease;
    box-shadow: 0px 0px 40px rgba(black, 0.1);
    border-radius: 8px;
  }
}

.active {
  .two-col-grid__item {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 400ms, transform 400ms ease;

    @for $i from 0 through 10 {
      &:nth-child(#{$i}) {
        transition-delay: 80ms * $i;
      }
    }


    &:hover {
      img {
        transform: scale(1.02);
        transition-delay: 0ms !important;
      }
    }
  }
}

.two-col-grid__item {
  opacity: 0;
  transform: translateY(30px);
}

@media(min-width: $bp-md) {
  .work-containers .two-col-grid {

    &__item {
     width: 48%;
    }
  }
}