.single-blog-hero.center {
  text-align: center;
}

.project-icon {
  max-width: 80px;
  margin: -40px auto 0 auto;
}



.single-products .content-area img {
  border-radius: 8px;
  overflow: hidden;
}