@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap');


h1 {
  font-size: 1.95em;
  margin: 0;
}

a {
  color: $black;
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  color: $black;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, a, li {
  font-size: 1em;
  line-height: 1.7;
}


@media(min-width: $bp-md) {
  body {
    font-size: 15px;
  }

  h1 {
    font-size: 2.8em;
  }
}


@media(min-width: $bp-lg) {
  body {
    font-size: 16px;
  }
  h1 {
    font-size: 2.4em;
  }
}


@media(min-width: $bp-xl) {
  body {
    font-size: 17px;
  }
}
