.pre-footer {
  display: flex;
  flex-wrap: wrap;

  &__item {
    // width: 50%;
    position: relative;
    width: 100%;
    padding: 13em 0em 5em 0em;
    background: $blue;
    overflow: hidden;
    text-decoration: none;
    color: white;

    &:hover {
      .section-bg {
        transform: scale(1.1);
      }
    }

    &:last-of-type {
      justify-content: flex-end;
    }

    .section-bg {
      transition: transform 240ms ease;
    }
  }

  &__content {
    color: white;
    width: 80%;
    margin: auto;
    font-size: 1.4em;
    font-weight: 100;
    position: relative;
    z-index: 5;

    p {
      margin-top: 0em;
      padding-top: 0.4em;
    }
  }

  .hr {
    height: 4px;
    width: 60px;
    background: white;
  }
}


@media(min-width: $bp-md) {
  .pre-footer {


    &__item {
      width: 50%;
    }

    &__content {
      width: 50%;
    }
  }
}