// FUNCTIONS/MIXINS ---------------//
//---------------------------------//

@function headings() {
  @return h1,h2,h3,h4,h5,h6;
}
@function textinputs() {
  @return 'input[type="text"], input[type="tel"],
  input[type="number"], input[type="email"], textarea';
}


@mixin sr-only() {
  position: absolute !important;
  height: 1px; width: 1px; 
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}


@mixin btn-reset() {
  appearance:none; border:none; background: none; outline:none; padding:0; margin:0; border-radius:0;
}




@mixin fill-parent() {
  display: block; position:absolute;
  top:0; left:0; width:100%; height:100%;
}


@mixin padding-bottom($height:1, $width:1, $childSelector:false) {
  position:relative; 
  margin: 0;
  padding-bottom: ($height/$width) * 100%;
  @if $childSelector {
    #{$childSelector} { 
      @include fill-parent();
    }
  }
};



@mixin clearfix() {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}


@mixin font-smoothing() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@mixin image-2x($path, $width, $height) {
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi) {
    background-image: url(#{$path});
    @include background-size($width $height);
  }
}

@mixin unselectable() {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@mixin hoverAnchor() {
  &:hover {
    color: darken($primary, 15%)
  }
}

@mixin triangle(
  $direction,
  $width,
  $height,
  $color
) {
  @if not index(
    "up" "up-right" "right" "down-right" "down" "down-left" "left" "up-left",
    $direction
  ) {
    @error "Direction must be `up`, `up-right`, `right`, `down-right`, " +
           "`down`, `down-left`, `left` or `up-left`.";
  } @else if not _is-color($color) {
    @error "`#{$color}` is not a valid color for the `$color` argument in " +
           "the `triangle` mixin.";
  } @else {
    border-style: solid;
    height: 0;
    width: 0;

    @if $direction == "up" {
      border-color: transparent transparent $color;
      border-width: 0 ($width / 2) $height;
    } @else if $direction == "up-right" {
      border-color: transparent $color transparent transparent;
      border-width: 0 $width $width 0;
    } @else if $direction == "right" {
      border-color: transparent transparent transparent $color;
      border-width: ($height / 2) 0 ($height / 2) $width;
    } @else if $direction == "down-right" {
      border-color: transparent transparent $color;
      border-width: 0 0 $width $width;
    } @else if $direction == "down" {
      border-color: $color transparent transparent;
      border-width: $height ($width / 2) 0;
    } @else if $direction == "down-left" {
      border-color: transparent transparent transparent $color;
      border-width: $width 0 0 $width;
    } @else if $direction == "left" {
      border-color: transparent $color transparent transparent;
      border-width: ($height / 2) $width ($height / 2) 0;
    } @else if $direction == "up-left" {
      border-color: $color transparent transparent;
      border-width: $width $width 0 0;
    }
  }
}


@mixin gradient() {
  background: linear-gradient(to top right, #f4ab12, #ff6d00);
}
