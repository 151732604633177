.section-intro {
  max-width: 800px;
  margin: auto;
  width: 80%;

  &.center {
    text-align: center;
  }

  &.padding-bottom {
    padding-bottom: 4em;
  }
}

.section-title {
  font-size: 1.6em;
  margin-bottom: 0em;
}